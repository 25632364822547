import React from 'react';

const TermsConditions = () => {
  return (
    <div style={{ padding: '20px' }}>
        <h1>Terms and Conditions for Chize</h1>

<h2>Introduction</h2>
<p>Welcome to <strong>Chize</strong> ("Application"). This Application is provided by <strong>Chize LLC</strong> ("we," "us," or "our"). By downloading or using our Application, you agree to be bound by these Terms and Conditions ("T&C") and our Privacy Policy.</p>

<h2>License</h2>
<p>The Application you have acquired is licensed, not sold, to you for use only under the terms of this license, unless the Application is accompanied by a separate license agreement, in which case the terms of that separate license agreement will govern.</p>
<ol>
    <li><strong>Scope of License:</strong> We grant you a non-transferable license to use the Application on any Apple-branded products that you own or control as permitted by the Usage Rules set forth in the App Store Terms of Service.</li>
    <li><strong>Consent to Use of Data:</strong> You agree that we may collect and use technical data and related information to facilitate software updates, provide product support, and other services to you related to the Application.</li>
    <li><strong>Termination:</strong> This license is effective until terminated by you or us. Your rights will terminate automatically without notice from us if you fail to comply with any of these terms.</li>
</ol>

<h2>Subscription Service</h2>
<p>Our Application offers auto-renewable subscription services that include features such as Unlimited Posts, Adjustable Distance, and Enhanced Location Suggestions.</p>

<h2>Auto-Renewal Subscription</h2>
<ul>
    <li><strong>Subscription Period:</strong> Your subscription will automatically renew at the end of each subscription period.</li>
    <li><strong>Cancellation:</strong> You may cancel your subscription through your account settings.</li>
    <li><strong>No Refunds:</strong> Payments are nonrefundable, and there are no refunds for partially used periods.</li>
</ul>

<h2>Changes to the Terms</h2>
<p>We reserve the right to make changes to these T&C at any time. If we make changes, we will post the updated T&C within the Application and update the "Last Updated" date.</p>

<h2>Governing Law</h2>
<p>These T&C are governed by and construed in accordance with the laws of the State of California, except that body of California law concerning conflicts of law.</p>

<h2>Contact Information</h2>
<p>For any questions, complaints, or claims with respect to the Application, you can contact us at <strong>feedback@chizeapp.com</strong>.</p>

<h2>Acknowledgement</h2>
<p><strong>Chize LLC</strong> and you acknowledge that this Agreement is concluded between <strong>Chize LLC</strong> and you only, and not with Apple, and <strong>Chize LLC</strong>, not Apple, is solely responsible for the Licensed Application and the content thereof.</p>

<h2>Third-Party Beneficiary</h2>
<p>Apple, and Apple's subsidiaries, are third-party beneficiaries of these T&C, and upon your acceptance of the terms and conditions of these T&C, Apple will have the right (and will be deemed to have accepted the right) to enforce these T&C against you as a third-party beneficiary thereof.</p>

    </div>
  )
}

export default TermsConditions;